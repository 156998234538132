// import { checkEmailExists } from '@/services/validationsHelper';
import {
    // required,
    minLength,
    maxLength,
    // email,
    requiredIf
} from 'vuelidate/lib/validators';
import passwordRules from '../password/Rules.js'

export const validationRules = {
    firstname: {
        required: requiredIf(function () {
            return !this.stay_anonymous
        }),
        minLength: minLength(2),
        maxLength: maxLength(50),
        onlyLattersValidate(value) {
            if (this.stay_anonymous) return true
            return /^[a-zA-Z][a-zA-Z\s]*$/g.test(value)
        }

    },
    lastname: {
        required: requiredIf(function () {
            return !this.stay_anonymous
        }),
        minLength: minLength(2),
        maxLength: maxLength(50),
        onlyLattersValidate(value) {
            if (this.stay_anonymous) return true
            return /^[a-zA-Z][a-zA-Z\s]*$/g.test(value)
        }

    },
    // email: {
    //     required,
    //     email: (val) => email(val.toLowerCase()),
    //     async emailExists(value) {
    //         // ignore this validator, if any of the previous fail
    //         if (!this.$v.email.required || !this.$v.email.email) { return true; }

    //         try {
    //             await checkEmailExists(value)
    //             return true
    //         } catch (error) {
    //             return false
    //         }
    //     },
    //     validateEmail(value) {
    //         const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //         return re.test(String(value).toLowerCase());
    //     }
    // },
    password: { ...passwordRules }
}
