<template>
    <div class="email__row">
        <form-input
            v-model.trim="emailValue"
            legend="Email address"
            prepend-icon="EmailOutlineIcon"
            type="text"
            name="email"
            :field.sync="emailValue"
            :errors="validationMessage($v.email)"
            :is-valid="$v.email.$dirty && !$v.email.$anyError"
            :loading="loadEmail"
            autocomplete="username"
            @input.native="$v.email.$touch()"
            @onChange="loadEmail = true"
        />
        <div class="email-verification__btn">
            <RecaptchaButton
                :validate-params="$v.$invalid"
                @checkField="verificationStart"
                @captchaVerify="verificationStart"
            >
                <template slot="button-content">
                    <button-loader v-if="email_validation_loading" />
                    <span v-else>Verify</span>
                </template>
            </RecaptchaButton>
        </div>
    </div>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { mapState } from 'vuex';
import Messages from '@/helpers/InterfaceMessages_en'
import { eventBus } from '@/helpers/event-bus/'
import { debounce } from 'lodash'

import ButtonLoader from '@/components/common/ButtonLoader.vue';

import Api from '@/helpers/api/index.js'

// validation
import { validationRules } from '@/validation/email/Rules.js'
import { formMessages } from '@/validation/email/Messages.js'
import formValidationMixin from '@/mixins/formValidationMixin.js'

// VUEX
import { mapEmailFields } from '@/store/modules/email'
import RecaptchaButton from '@/components/common/RecaptchaButton';

export default {
    name: 'PhoneNumberField',
    components: {
        ButtonLoader,
        RecaptchaButton
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            emailValue: '',
            loadEmail: false,
            email_validation_loading: false
        }
    },
    computed: {
        ...mapState('email', {
            started: 'started',
            verified: 'verified'
        }),
        ...mapEmailFields([
            'form_data.email'
        ]),
        isFormValid() {
            return !this.$v.$invalid
        }
    },
    watch: {
        emailValue: debounce(function (newEmail) {
            this.email = newEmail.trim()
            this.loadEmail = false
        }, 300)
        // phoneNumberValue(newPhoneNumber) {
        //     this.phone_number = newPhoneNumber
        // }
    },
    mounted() {
        if (this.$route.name === 'profile_email') {
            this.email = ''
            eventBus.$on('saveSettings', () => {
                if (this.$v.$invalid) {
                    this.validate()
                    return
                }
                eventBus.$emit('validate', true)
            })
            return
        }
        eventBus.$on('clearEmail', () => {
            this.$v.$reset()
        })
        this.emailValue = this.email
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async verificationStart(payload) {
            if (this.$v.$invalid) {
                await this.isValid()
                const errorName = this.findNameOfFirstError(this.$v);
                eventBus.$emit('showSnackBar', `Please complete the form: ${errorName}`, 'error');
            } else {
                this.email_validation_loading = true
                Api.post('/api/email-verification/send-pin', {
                    email: this.email,
                    recaptcha: payload
                }).then(() => {
                    this.$store.commit('email/START')
                    eventBus.$emit('showSnackBar', Messages.modal_sent_email_verification_pin.body, 'success');
                }).catch((error) => {
                    eventBus.$emit('showSnackBar', error, 'error');
                }).finally(() => {
                    this.email_validation_loading = false
                })
            }
        }
    }
}
</script>
